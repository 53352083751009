import { AuthService } from '../shared/auth.service';
import {
    Component,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginModel } from './login.model';
import { Globals } from '../common/globals';
import { NavigationExtras } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/functions';
import { TranslationPipe } from '../shared/translation.pipe';
import { CompanyService } from '../shared/company.service';
import { HcLocalStorage } from '../common/local-storage';
import { AGGridLicenser } from '../common/report-grid/tools/ag-grid-license';
import { InterfaceService } from '../shared/interfaces.service';
import { TranslationService } from '../shared/translation.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MSALInstanceFactory } from '../shared/MSALInstanceFactory';
import { AuthError, BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication, SsoSilentRequest } from '@azure/msal-browser';
import { BuildConfigService } from '../shared/build-config.service';
import { BehaviorSubject, catchError, lastValueFrom, of } from 'rxjs';

const jwtHelper = new JwtHelperService();

@Component({
    selector: 'hc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit  {

    public languageReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public loginForm: FormGroup;
    public errorMessage: string = null;
    public inProgress = false;
    public standardLoginEnabled = true;

    public azureLoginEnabled = false;
    public isIframe = false;
    public errorAzure$: BehaviorSubject<string> = new BehaviorSubject(null);
    

    public multipleTenants = false;

    public selectedTenantID: number = null;

    private tenants = [];

    private jwtTokens: true | { id: any; name: any; jwt: string; }[];

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private companyService: CompanyService,
        public globals: Globals,
        private cd: ChangeDetectorRef,
        private hcLocalStorage: HcLocalStorage,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private trans: TranslationPipe,
        private gridLic: AGGridLicenser,
        private interfaceService: InterfaceService,
        private translationService: TranslationService,
        private buildConfig: BuildConfigService,
    ) {
        this.languageReady.next(this.translationService.langLoaded);

        let a = this.translationService.instant('admin.web.logout'); // Just to force translations to load, if not already

        this.globals.languageLoaded.subscribe(l => {
            console.log('language ready');
            this.languageReady.next(true); // alway show content, even if language loading failed
            if (!this.cd['destroyed']) {
                this.cd.detectChanges();
            }
        });

        if (this.loggedIn()) {
            this.router.navigate(['/']);
        }

        this.loginForm = this.formBuilder.group({
            Username: new FormControl('', Validators.required),
            Password: new FormControl('', Validators.required),
            RememberMe: new FormControl(),
            TenantId: new FormControl('1', Validators.required)
        });

        this.loginForm.controls['TenantId'].valueChanges.subscribe(v => {
            this.selectedTenantID = v;
        },
            (err) => console.error(err));
    }

    ngOnInit(): void {
        this.azureLoginEnabled = this.buildConfig.azureLogin.enabled;
        this.standardLoginEnabled = this.buildConfig.standardLogin.enabled;
    }

    async ngAfterViewInit() {
        if (!this.azureLoginEnabled || this.standardLoginEnabled || localStorage.getItem('avoidMsalLogin') == 'true') {
            return;
        }

        this.inProgress = true;
        this.cd.detectChanges();
    
        let c = <SsoSilentRequest>{
            scopes: ["openid", "profile", "email"],
            
            // loginHint: preferred_username,
            // extraQueryParameters: { domain_hint: "organizations" },
        };
    
        let l = await lastValueFrom(this.msalService.ssoSilent(c).pipe(catchError((e: InteractionRequiredAuthError | BrowserAuthError) => {
            console.log(e);
            this.inProgress = false;
            if (e.errorCode === 'interaction_required') {
                this.loginAzure();
            }
            if (e.errorCode === 'monitor_window_timeout') {
                // Do nothing
            }
            this.cd.detectChanges();
            return of({ idToken: null });
        })));

        if (l.idToken == null) {
            this.inProgress = false;
            return;
        }
        let accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length === 1) {
    
            let res = await lastValueFrom(this.authService.externalLogin(l.idToken, "azure")
            .pipe(catchError((e => { 
                console.error(e); return of (null);
            }))));

            if (res === null)  {
                localStorage.setItem('avoidMsalLogin', 'false');
                this.authService.logoutAzure();
                this.cd.detectChanges();
                return;
            }

            var token = (<any>res).access_token;
            this.authService.setToken(token);
            await this.loadPrerequities();
        }
    }

    loggedIn(): boolean {
        let token = this.globals.getToken();
        if (!token) { return false; }
        return !jwtHelper.isTokenExpired(token);
    }

    loginAzure() {

        if (this.inProgress) {
            return;
        }
        console.log('loginAzure()');
        this.inProgress = true;
        this.cd.detectChanges();

        // Must be re-initialized - BuildConfig is no loaded in app.modules, so the original configuration is not valid
        this.msalService.instance = MSALInstanceFactory(this.buildConfig);

        this.isIframe = window !== window.parent && !window.opener;

        let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;

        msalInstance["browserStorage"].clear();

        this.msalService.loginPopup()
        .subscribe({
            next: async (result) => {
                console.log(result);
                var accountID = result.account.homeAccountId;
                this.authService.setMsalAccountID(accountID);
                let res = await lastValueFrom(this.authService.externalLogin(result.idToken, "azure").pipe(
                    catchError((e) => { 
                        this.errorAzure$.next("common.error.unknown-email");
                        this.authService.logoutAzure();
                        this.cd.detectChanges();
                        console.log('login-error', e);
                        this.inProgress = false;
                        return of(null);
                    }
                )));

                this.setLoginDisplay();

                if (res === null) {
                    localStorage.setItem('avoidMsalLogin', 'false');
                    return;
                }
                var token = (<any>res).access_token;
                this.authService.setToken(token);

                await this.loadPrerequities();
            },
            error: (error: AuthError) => {
                let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
                msalInstance["browserStorage"].clear();
                if (error.errorCode === 'state_not_found') {
                    this.errorAzure$.next("common.error.unknown-email");
                    console.log('login-error', error);
                    this.authService.logoutAzure();
                    this.inProgress = false;
                }
            }
        });
    }

    private async loadPrerequities() {

        // Load language set for selected tenant
        await this.translationService.get(this.globals.getLanguage());

        await this.interfaceService.load();
        this.gridLic.setAGGridLicense();

        let storedBreadcrumb = sessionStorage.getItem('hc_route');

        this.inProgress = false;

        if (!!storedBreadcrumb) {
            this.router.navigate([this.globals.currentNavItem().path], <NavigationExtras>this.globals.currentNavItem().data);
        } else {
            this.globals.navigateClearTo('');
        }

    }

    async onSubmit(event: Event) {
        event.preventDefault();

        this.inProgress = true;
        this.errorMessage = null;

        if (!this.loginForm.valid) {
            return;
        }

        let formModel = this.loginForm.value as LoginModel;

        // Already have JWT tokens, just need setup
        if (this.multipleTenants) {
            let jwtToken = (this.jwtTokens as Array<any>).filter(f => f.id === formModel.TenantId)[0].jwt;

            // TODO: Detect if token has user, admin or superadmin claim
            // let tokenDecoded = jwtHelper.decodeToken(jwtToken);

            await this.authService.setToken(jwtToken);
            sessionStorage.setItem('loggedUserName', formModel.Username);
            localStorage['tenantId'] = formModel.TenantId;

            await this.loadPrerequities();

            return;
        } else {
            this.globals.setMultiloginTenants([]);
        }

        let jwtTokens = await this.authService.multilogin(formModel.Username, formModel.Password);
        this.jwtTokens = jwtTokens;

        if (isNullOrUndefined(jwtTokens) || jwtTokens.valueOf() === false) {
            this.inProgress = false;
            this.errorMessage = this.trans.transform('admin.web.invalid-login');
            this.cd.detectChanges();
            return;
        }

        if (jwtTokens.valueOf() === true) {
            let storedBreadcrumb = sessionStorage.getItem('hc_route');
            localStorage['tenantId'] = formModel.TenantId;
            this.loadPrerequities();
            if (!!storedBreadcrumb) {
                this.router.navigate([this.globals.currentNavItem().path], <NavigationExtras>this.globals.currentNavItem().data);
            } else {
                this.globals.navigateClearTo('');
            }
        } else {

            this.tenants = (this.jwtTokens as Array<any>);

            // Filter eshop only accounts
            this.tenants = this.tenants.filter(t => {
                let decodedToken = jwtHelper.decodeToken(t.jwt);

                if (decodedToken.user_type === 'Contact') {
                    return false;
                }

                if (Array.isArray(decodedToken.role)) {
                    return decodedToken.role.indexOf('admin') > -1 ||
                           decodedToken.role.indexOf('sysadmin') > -1 ||
                           decodedToken.role.indexOf('user') > -1;
                } else {
                    return decodedToken.role === 'admin' ||
                           decodedToken.role === 'sysadmin' ||
                           decodedToken.role === 'user';
                }
            });

            if (this.tenants.length === 0) {
                this.inProgress = false;
                this.errorMessage = this.trans.transform('admin.web.invalid-login');
                this.cd.detectChanges();
                return;
            }

            this.multipleTenants = true;

            this.tenants = this.tenants.sort((a, b) => {
                if ( a.name < b.name ) { return -1; }
                if ( a.name > b.name ) { return 1; }
                return 0;
            });

            this.globals.setMultiloginTenants(this.tenants);

            let selectedTenant = this.tenants.find(t => t.id === localStorage['tenantId']);
            if (selectedTenant) {
                formModel.TenantId = selectedTenant.id;
                this.loginForm.controls['TenantId'].setValue(selectedTenant.id);
            } else {
                this.loginForm.controls['TenantId'].setValue(null);
                formModel.TenantId = null;
            }
            this.cd.detectChanges();
        }
    }

    setLoginDisplay() {
        // this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
      }
}

